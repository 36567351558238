import React from "react"

import "../../../styles/solution.scss"

const Img = {
    whats:"https://assets.pessoalize.com/solutions/whats.png",
    phone:"https://assets.pessoalize.com/solutions/phone.png",
    email:"https://assets.pessoalize.com/solutions/email.png",
    mensager:"https://assets.pessoalize.com/solutions/mensager.png",
    conversation:"https://assets.pessoalize.com/solutions/conversation.png",
    libras:"https://assets.pessoalize.com/solutions/libras.png",
}

const Header = () => {
    return(
    <div className="container-header">
        <div className="wrap-header">
            <div className="wrap-text">
                <p className="title-header">SOLUÇÕES</p>
                <div>
                    <text>
                        Criamos conexão entre a sua marca e o seu público: onde  <q className="estiver"> ele </q>
                        , como ele <q className="quiser-precisar"> e </q> ser atendido. Mesmo.
                    </text>
                </div>
            </div>
    
            <div className="planet">
                <img className="phone" alt="" src={Img.phone} />
                <img className="whats" alt="" src={Img.whats} />
                <img className="libras" alt="" src={Img.libras} />
                <img className="email" alt="" src={Img.email} />
                <img className="mensager" alt="" src={Img.mensager} />
                <img className="conversation" alt="" src={Img.conversation} />
            </div>
        </div>
    </div>
    )
    }

export default Header
