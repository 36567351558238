import React from "react"

const ObjLinkImg = {
    phone:"https://assets.pessoalize.com/solutions/phoneSpeak.png",
    whats:"https://assets.pessoalize.com/solutions/whatsSpeak.png",
    email:"https://assets.pessoalize.com/solutions/emailSpeak.png",
    mic:"https://assets.pessoalize.com/solutions/micSpeak.png"
}

const YouNeed = () => {
    return(
        <div className="container">
            <div className="Wrap-need">
                <h1>
                    Fala pra gente, o que você precisa?
                </h1>
                <p>
                    Vamos entender juntos o que <br/>mais faz sentido no seu dia a dia.
                </p>
                <div className="wrap-social-need">
                    <img src={ObjLinkImg.mic} alt=""/>
                    <img onClick={()=>window.open("https://wa.me/5548991289058")} src={ObjLinkImg.whats} alt=""/>
                    <img src={ObjLinkImg.email} alt=""/>
                    <img src={ObjLinkImg.phone} alt=""/>
                </div>
            </div>
            
        </div>
    )
}
export default YouNeed