import React from "react"

const YouBetter = () => {
    return(
        <div className="container">
            <div className="Wrap-Better">
                <q> FAZER MELHOR. </q>
                <p>
                    Se o mercado vai acompanhar tudo 
                    <br/>isso que podemos fazer juntos? 
                    <br/>Não sei, ele que lute.
                </p>
            </div>
        </div>
    )
}
export default YouBetter