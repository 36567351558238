import React from "react"

import Layout from "../components/Layout/layout"
import Header from "../components/Solutions/Header"
import Bonds from "../components/Solutions/Bonds"
import Management from "../components/Solutions/Management"
import Methodology from "../components/Solutions/Methodology"
import YouNeed from "../components/Solutions/YouNeed"
import ToBetter from "../components/Solutions/ToBetter"

const HomePage = () => (
  <Layout>
    <Header/>
    <Bonds />
    <Management/>
    <Methodology/>
    <YouNeed />
    <ToBetter />
  </Layout>
)

export default HomePage
