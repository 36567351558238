import React from "react"

import "../../../styles/solution.scss"

const loopEllipsi = () => {
    return(
        Array.from(Array(12)).map((index) => <img key={index}src="https://assets.pessoalize.com/solutions/Ellipse.png" />)
    )
}
const ObjImgCard = {
    card1 : "https://assets.pessoalize.com/solutions/card1.png",
    card2 : "https://assets.pessoalize.com/solutions/card2.png",
    card3 : "https://assets.pessoalize.com/solutions/card3.png",
}

const Methodology = () =>{
    return(
        <div className="container">
            <h1 className="title-methodology">METODOLOGIA</h1>
            <div>
                <div className="wrap-ellipse">
                    {loopEllipsi()}
                </div>
                <div className="wrap-cards-methodolgy">
                    <div className="cards-methodology card-1" >
                        <p><q>MERGULHO</q></p>
                        <p>Mapeamos absolutamente todos <br/> os seus canais digitais e <br/>apontamos o que precisa ser<br/> feito e melhorado, levando em consideração dados, usabilidade, acessibilidade e eficácia digital.</p>
                        <p>Simples assim.</p>
                    </div>
                    <div className="trace-methodology"/>
                    <div className="cards-methodology card-2" >
                        <p><q>TRILHA</q></p>
                        <p>Estratégia que não resolve<br/> problema é desperdício, então aplicamos na prática tudo que foi mapeado para testar e gerar experiências entre marcas e<br/> clientes. </p>
                        <p>Mas sinceramente? Nada disso <br/>precisa ou vai ser burocrático. Agilidade, simplicidade e<br/> performance é pré-requisito aqui.</p>
                    </div>
                    <div className="trace-methodology"/>
                    <div className="cards-methodology card-3" >
                        <p><q>MÃO</q><q className="word-na">MASSA</q></p>
                        <p>Sim, usamos todas aquelas ferramentas tradicionais do mercado de call center, mas com inteligência analítica, apoio tecnológico e pessoas correndo lado a lado do seu time para performar mais e melhor a cada dia.</p>
                        <p className="word-roi">E é exatamente assim <br/>que entregamos ROI.<br/></p>
                    </div>
                </div>
                <div className="wrap-ellipse">
                    {loopEllipsi()}
                </div>
            </div>
        </div>
     
    )
}
export default Methodology