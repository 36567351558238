import React from "react"

const Management = () => {
    return(
        <div className="container">
            <h1 className="title-management">Gestão e conexão de <br/> todos os pontos de contato: </h1>
            <div className="wrap-card">
                <div className="wrap-first-card">
                    <div className="card-face">
                        <img src="https://assets.pessoalize.com/solutions/librawhite.png" alt="" />
                        <p>ATENDIMENTO <br/>EM LIBRAS</p>
                    </div>
                    <div className="card-back-face">
                        <div>
                            <p>Acessibilidade e apoio total para pessoas surdas ou com deficiência auditiva. Por vídeo e em libras, afinal: sinais é a nossa língua-mãe. </p>
                            <p>Se você acha que isso é muito pra sua empresa, dá <q className="word-uma">uma</q></p>
                        </div>
                        <button>Mais detalhes</button>
                    </div>
                </div>
                <div className="wrap-second-card">
                    <div className="fill-management-top"/>
                    <div className="card-face">
                        <img src="https://assets.pessoalize.com/solutions/computer.png" alt="" />
                        <p>ATENDMINETO<br/> DIGITAL <br/>OMNICHANNEL</p>
                    </div>
                    <div className="card-back-face">
                        <div>
                            <p>Ícones + Menções, interações, dúvidas e necessidades mapeadas e respondidas. </p>
                            <p>De forma digital, conectiva, pessoal e com o tom de voz da sua marca.</p>
                        </div>
                        <button>Mais detalhes</button>
                    </div>
                </div>
                <div className="wrap-third-card">
                    <div className="card-face">
                        <img src="https://assets.pessoalize.com/solutions/mic.png" alt="" />
                        <p>PORTAIS DE<br/>RECLAMAÇÃO</p>
                    </div>
                    <div className="card-back-face">
                        <div>
                            <p>Até aqueles que ninguém<br/>tem paciência de olhar e responder.<br/>
                                ReclameAqui, Anatel, Basen, Consumidor.gov. (levamos ele pro explorar - onde falamos da importância da presença digital ativa e resolutiva <br/>nesses canais).
                            </p>
                        </div>
                        <button>Mais detalhes</button>
                    </div>
                    <div className="fill-management-bottom"/>
                </div>
                <div className="wrap-fourth-card">
                    <div className="card-face active-receptive">
                        <img src="https://assets.pessoalize.com/solutions/phone2.png" alt="" />
                        <p>ATIVO E RECEPTIVO<br/> DE VOZ E VÍDEO</p>
                    </div>
                    <div className="card-back-face">
                        <div style={{height:"176px", marginTop:"1rem"}}>
                            <p>Aqui não tem nada <br/>novo sob o sol. </p>
                            <p>O seu atendimento descomplicado. <br/>
                            Do jeito que você precisa.
                            </p>
                        </div>
                        <button>Mais detalhes</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Management