import React from "react"

const Bonds = () =>{
    return(
        <div className="bg-bonds">
            <div className="filter-bonds">
                <div className="container">
                    <div className="wrap-message-bonds">
                        <div className="first-message-bonds">
                            <p >
                                <q className="aqui">Aqui </q>  produto de prateleira.
                            </p>
                        </div>
                        <div className="second-message-bonds">
                            <p>
                               Tem foco. E é um  <q className="so">só:</q>
                            </p>
                        </div>
                        <div className="third-message-bonds">
                            <p>
                            Tudo é adaptável 
                                à sua necessidade. E à de todos.
                            </p>
                        </div>
                    </div>                
                </div>                
            </div>
        </div>
    )
}

export default Bonds